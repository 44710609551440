module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hunterlily & Co.","short_name":"Hunterlily","description":"Digital Strategy, Activated.","lang":"en","start_url":"/","icon":"src/images/logos/Hunterlily_Logo.svg","background_color":"#2f3459","theme_color":"#fdf8eb","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"opw0qkc"}},
    }]
